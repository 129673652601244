export function toLocalPath(url) {
  if (url) {
    url = `/static${url}`;
    return url;
  } else {
    return "";
  }
}

export function stripScriptTag(script){
  return script.replace(new RegExp(/<.*?>/g),"");
}

export function stripHtml(text) {  
  if (typeof document === 'undefined')  { 
    let regex = /(<([^>]+)>)/ig , result = text.replace(regex, "");
    return result; 
  }

  if (!text) return;

  var tempDivElement = document.createElement('div');
  tempDivElement.innerHTML = text;
  return tempDivElement.textContent || tempDivElement.innerText || "";
}

export function isVideo(media) {
  let videoExtensions = [ ".mp4" ];
  let mediaExtension = media.substring(media.lastIndexOf('.'), media.length);
  let isMediaVideo = !!videoExtensions.find(x => x === mediaExtension);
  return isMediaVideo;
}

export function setMonthDayToEndofMonth(date) {
  let days = daysInMonth(date.getYear(), date.getMonth());
  date.setDate(days);
  return date;
}

export function daysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}

export function toDanishCurrency(amount) {
  return formatMoney('DKK ', amount, 0, ',', '.');
}

function formatMoney(currencySign, amount, decimalCount, decimal, thousands) {
  try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;

      return currencySign + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + 
        (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : ",-");
  } catch (e) {
      console.log(e)
  }
  // decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
  // decSep = typeof decSep === "undefined" ? "." : decSep;
  // thouSep = typeof thouSep === "undefined" ? "," : thouSep;
  // // var sign = number < 0 ? "-" : "";
  // let sign = "Kr. ";
  // let i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
  // let j = i.length;
  // j = j > 3 ? j % 3 : 0;

  // return sign +
  //     (j ? i.substr(0, j) + thouSep : "") +
  //     i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
  //     (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : ",-");
}

export function countryCodeAndIso() {
  const countries = [{countrycode: "1", isocode: "US"},{countrycode: "1", isocode: "CA"},{countrycode: "1-242", isocode: "BS"},
      {countrycode: "1-246", isocode: "BB"},{countrycode: "1-264", isocode: "AI"},{countrycode: "1-268", isocode: "AG"},
      {countrycode: "1-284", isocode: "VG"},{countrycode: "1-340", isocode: "VI"},{countrycode: "1-345", isocode: "KY"},
      {countrycode: "1-441", isocode: "BM"},{countrycode: "1-473", isocode: "GD"},{countrycode: "1-649", isocode: "TC"},
      {countrycode: "1-664", isocode: "MS"},{countrycode: "1-670", isocode: "MP"},{countrycode: "1-671", isocode: "GU"},
      {countrycode: "1-684", isocode: "AS"},{countrycode: "1-721", isocode: "SX"},{countrycode: "1-758", isocode: "LC"},
      {countrycode: "1-767", isocode: "DM"},{countrycode: "1-784", isocode: "VC"},{countrycode: "1-787", isocode: "PR"},
      {countrycode: "1-809", isocode: "DO"},{countrycode: "1-868", isocode: "TT"},{countrycode: "1-869", isocode: "KN"},
      {countrycode: "1-876", isocode: "JM"},{countrycode: "20", isocode: "EG"},{countrycode: "211", isocode: "SS"},
      {countrycode: "212", isocode: "MA"},{countrycode: "212", isocode: "EH"},{countrycode: "213", isocode: "DZ"},
      {countrycode: "216", isocode: "TN"},{countrycode: "218", isocode: "LY"},{countrycode: "220", isocode: "GM"},
      {countrycode: "221", isocode: "SN"},{countrycode: "222", isocode: "MR"},{countrycode: "223", isocode: "ML"},
      {countrycode: "224", isocode: "GN"},{countrycode: "225", isocode: "CI"},{countrycode: "226", isocode: "BF"},
      {countrycode: "227", isocode: "NE"},{countrycode: "228", isocode: "TG"},{countrycode: "229", isocode: "BJ"},
      {countrycode: "230", isocode: "MU"},{countrycode: "231", isocode: "LR"},{countrycode: "232", isocode: "SL"},
      {countrycode: "233", isocode: "GH"},{countrycode: "234", isocode: "NG"},{countrycode: "235", isocode: "TD"},
      {countrycode: "236", isocode: "CF"},{countrycode: "237", isocode: "CM"},{countrycode: "238", isocode: "CV"},
      {countrycode: "239", isocode: "ST"},{countrycode: "240", isocode: "GQ"},{countrycode: "241", isocode: "GA"},
      {countrycode: "242", isocode: "CG"},{countrycode: "243", isocode: "CD"},{countrycode: "244", isocode: "AO"},
      {countrycode: "245", isocode: "GW"},{countrycode: "246", isocode: "IO"},{countrycode: "248", isocode: "SC"},
      {countrycode: "249", isocode: "SD"},{countrycode: "250", isocode: "RW"},{countrycode: "251", isocode: "ET"},
      {countrycode: "252", isocode: "SO"},{countrycode: "253", isocode: "DJ"},{countrycode: "254", isocode: "KE"},
      {countrycode: "255", isocode: "TZ"},{countrycode: "256", isocode: "UG"},{countrycode: "257", isocode: "BI"},
      {countrycode: "258", isocode: "MZ"},{countrycode: "260", isocode: "ZM"},{countrycode: "261", isocode: "MG"},
      {countrycode: "262", isocode: "YT"},{countrycode: "262", isocode: "RE"},{countrycode: "263", isocode: "ZW"},
      {countrycode: "264", isocode: "NA"},{countrycode: "265", isocode: "MW"},{countrycode: "266", isocode: "LS"},
      {countrycode: "267", isocode: "BW"},{countrycode: "268", isocode: "SZ"},{countrycode: "269", isocode: "KM"},
      {countrycode: "27", isocode: "ZA"},{countrycode: "290", isocode: "SH"},{countrycode: "291", isocode: "ER"},
      {countrycode: "297", isocode: "AW"},{countrycode: "298", isocode: "FO"},{countrycode: "299", isocode: "GL"},
      {countrycode: "30", isocode: "GR"},{countrycode: "31", isocode: "NL"},{countrycode: "32", isocode: "BE"},
      {countrycode: "33", isocode: "FR"},{countrycode: "34", isocode: "ES"},{countrycode: "350", isocode: "GI"},
      {countrycode: "351", isocode: "PT"},{countrycode: "352", isocode: "LU"},{countrycode: "353", isocode: "IE"},
      {countrycode: "354", isocode: "IS"},{countrycode: "355", isocode: "AL"},{countrycode: "356", isocode: "MT"},
      {countrycode: "357", isocode: "CY"},{countrycode: "358", isocode: "FI"},{countrycode: "359", isocode: "BG"},
      {countrycode: "36", isocode: "HU"},{countrycode: "370", isocode: "LT"},{countrycode: "371", isocode: "LV"},
      {countrycode: "372", isocode: "EE"},{countrycode: "373", isocode: "MD"},{countrycode: "374", isocode: "AM"},
      {countrycode: "375", isocode: "BY"},{countrycode: "376", isocode: "AD"},{countrycode: "377", isocode: "MC"},
      {countrycode: "378", isocode: "SM"},{countrycode: "379", isocode: "VA"},{countrycode: "380", isocode: "UA"},
      {countrycode: "381", isocode: "RS"},{countrycode: "382", isocode: "ME"},{countrycode: "383", isocode: "XK"},
      {countrycode: "385", isocode: "HR"},{countrycode: "386", isocode: "SI"},{countrycode: "387", isocode: "BA"},
      {countrycode: "389", isocode: "MK"},{countrycode: "39", isocode: "IT"},{countrycode: "40", isocode: "RO"},
      {countrycode: "41", isocode: "CH"},{countrycode: "420", isocode: "CZ"},{countrycode: "421", isocode: "SK"},
      {countrycode: "423", isocode: "LI"},{countrycode: "43", isocode: "AT"},{countrycode: "44", isocode: "GB"},
      {countrycode: "44-1481", isocode: "GG"},{countrycode: "44-1534", isocode: "JE"},{countrycode: "44-1624", isocode: "IM"},
      {countrycode: "45", isocode: "DK"},{countrycode: "46", isocode: "SE"},{countrycode: "47", isocode: "SJ"},
      {countrycode: "47", isocode: "NO"},{countrycode: "48", isocode: "PL"},{countrycode: "49", isocode: "DE"},
      {countrycode: "500", isocode: "FK"},{countrycode: "501", isocode: "BZ"},{countrycode: "502", isocode: "GT"},
      {countrycode: "503", isocode: "SV"},{countrycode: "504", isocode: "HN"},{countrycode: "505", isocode: "NI"},
      {countrycode: "506", isocode: "CR"},{countrycode: "507", isocode: "PA"},{countrycode: "508", isocode: "PM"},
      {countrycode: "509", isocode: "HT"},{countrycode: "51", isocode: "PE"},{countrycode: "52", isocode: "MX"},
      {countrycode: "53", isocode: "CU"},{countrycode: "54", isocode: "AR"},{countrycode: "55", isocode: "BR"},
      {countrycode: "56", isocode: "CL"},{countrycode: "57", isocode: "CO"},{countrycode: "58", isocode: "VE"},
      {countrycode: "590", isocode: "MF"},{countrycode: "590", isocode: "BL"},{countrycode: "591", isocode: "BO"},
      {countrycode: "592", isocode: "GY"},{countrycode: "593", isocode: "EC"},{countrycode: "595", isocode: "PY"},
      {countrycode: "597", isocode: "SR"},{countrycode: "598", isocode: "UY"},{countrycode: "599", isocode: "CW"},
      {countrycode: "599", isocode: "AN"},{countrycode: "60", isocode: "MY"},{countrycode: "61", isocode: "CX"},
      {countrycode: "61", isocode: "CC"},{countrycode: "61", isocode: "AU"},{countrycode: "62", isocode: "ID"},
      {countrycode: "63", isocode: "PH"},{countrycode: "64", isocode: "PN"},{countrycode: "64", isocode: "NZ"},
      {countrycode: "65", isocode: "SG"},{countrycode: "66", isocode: "TH"},{countrycode: "670", isocode: "TL"},
      {countrycode: "672", isocode: "AQ"},{countrycode: "673", isocode: "BN"},{countrycode: "674", isocode: "NR"},
      {countrycode: "675", isocode: "PG"},{countrycode: "676", isocode: "TO"},{countrycode: "677", isocode: "SB"},
      {countrycode: "678", isocode: "VU"},{countrycode: "679", isocode: "FJ"},{countrycode: "680", isocode: "PW"},
      {countrycode: "681", isocode: "WF"},{countrycode: "682", isocode: "CK"},{countrycode: "683", isocode: "NU"},
      {countrycode: "685", isocode: "WS"},{countrycode: "686", isocode: "KI"},{countrycode: "687", isocode: "NC"},
      {countrycode: "688", isocode: "TV"},{countrycode: "689", isocode: "PF"},{countrycode: "690", isocode: "TK"},
      {countrycode: "691", isocode: "FM"},{countrycode: "692", isocode: "MH"},{countrycode: "7", isocode: "RU"},
      {countrycode: "7", isocode: "KZ"},{countrycode: "81", isocode: "JP"},{countrycode: "82", isocode: "KR"},
      {countrycode: "84", isocode: "VN"},{countrycode: "850", isocode: "KP"},{countrycode: "852", isocode: "HK"},
      {countrycode: "853", isocode: "MO"},{countrycode: "855", isocode: "KH"},{countrycode: "856", isocode: "LA"},
      {countrycode: "86", isocode: "CN"},{countrycode: "880", isocode: "BD"},{countrycode: "886", isocode: "TW"},
      {countrycode: "90", isocode: "TR"},{countrycode: "91", isocode: "IN"},{countrycode: "92", isocode: "PK"},
      {countrycode: "93", isocode: "AF"},{countrycode: "94", isocode: "LK"},{countrycode: "95", isocode: "MM"},
      {countrycode: "960", isocode: "MV"},{countrycode: "961", isocode: "LB"},{countrycode: "962", isocode: "JO"},
      {countrycode: "963", isocode: "SY"},{countrycode: "964", isocode: "IQ"},{countrycode: "965", isocode: "KW"},
      {countrycode: "966", isocode: "SA"},{countrycode: "967", isocode: "YE"},{countrycode: "968", isocode: "OM"},
      {countrycode: "970", isocode: "PS"},{countrycode: "971", isocode: "AE"},{countrycode: "972", isocode: "IL"},
      {countrycode: "973", isocode: "BH"},{countrycode: "974", isocode: "QA"},{countrycode: "975", isocode: "BT"},
      {countrycode: "976", isocode: "MN"},{countrycode: "977", isocode: "NP"},{countrycode: "98", isocode: "IR"},
      {countrycode: "992", isocode: "TJ"},{countrycode: "993", isocode: "TM"},{countrycode: "994", isocode: "AZ"},
      {countrycode: "995", isocode: "GE"},{countrycode: "996", isocode: "KG"},{countrycode: "998", isocode: "UZ"}
  ];

  return countries;
}