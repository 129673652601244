import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import { toLocalPath, stripScriptTag } from '../utilities/helpers';

function SEO({ page, businessSchema, schemas, globalSettings, headerScript, favicon, generalSeoSetting, customLdScript, links, bgBanner }) {
  const {
    title, description, keywords, 
    noFollow, noIndex,
    cannonicalUrl,
    //googleImage, googleDescription, googleTitle, 
    // GooglePublisherPage,
    schemaLdScript,
    ogDescription, ogImage, ogTitle,
    twitterDescription, twitterImage, twitterTitle,
  } = page;

  const {
    twitterTitle: globalTwitterTitle,
    twitterDescription: globalTwitterDescription,
    ogTitle: globalOgTitle,
    ogDescription: globalOgDescription,
    //GoogleTitle: globalGoogleTitle,
    //GoogleDescription: globalGoogleDescription,
    defaultImage: globalDefaultImage,
  } = globalSettings;

  let url = null;
  if (typeof window !== "undefined") {
    url = window.location.href;
  }

  let robots =  `${noFollow ? "NOFOLLOW" : "FOLLOW"},${noIndex ? "NOINDEX" : "INDEX"}`;
  // let robots =  `NOFOLLOW,NOINDEX`;
  let displayedFavicon = favicon ? '/static' + favicon.url : `/favicon.ico`;

  let ldScripts = [];

  if (schemas) {
    let extSchemas = stripScriptTag(schemas);
    ldScripts.push({
      type: "application/ld+json",
      innerHTML: extSchemas
    });
  }

  if (businessSchema) {
    let extSchemas = stripScriptTag(businessSchema);
    ldScripts.push({
      type: "application/ld+json",
      innerHTML: extSchemas
    });
  }

  if (schemaLdScript) {
    let extSchemas = stripScriptTag(schemaLdScript);
    ldScripts.push({
      type: "application/ld+json",
      innerHTML: extSchemas
    });
  }

  if (customLdScript) {
    if (customLdScript.length > 0) {
      customLdScript.forEach(c => {
        if (c) {
          ldScripts.push({
            type: "application/ld+json",
            innerHTML: c
          });
        }
      });
    }
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en-US',
      }}
      base={{ href: '/' }}
      title={title}
      titleTemplate={`Dinesen Collection | ${title}`}
      script={ldScripts}
      meta={[
        { name: `description`, content: description || title },
        { name: `keywords`, content: keywords },
        { name: `robots`, content: robots },
        { name: `google-site-verification`, content: 'JQQPvnz2YbbNyP0lImrpnPLF2LQZaHx39bVadcIK5To'},

        { property: "og:title", content: `Dinesen Collection | ${ogTitle || title || globalOgTitle}` },
        { property: "og:description", content: ogDescription || description || globalOgDescription, },
        { property: "og:url", content: cannonicalUrl || url },
        { property: "og:image", content: toLocalPath(ogImage) || toLocalPath(globalDefaultImage), },
        { property: "og:locale", content: "en_US" },
        { property: "og:site_name", content: "dinesencollection.dk" },
        { property: "og:type", content: "website" },

        { property: "fb:appid", content: generalSeoSetting.fbAppId },
        { property: "fb:admins", content: generalSeoSetting.fbAdmin },

        { property: "twitter:site", content: generalSeoSetting.twitterSite },
        { property: "twitter:creator", content: generalSeoSetting.twitterCreator },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: `Dinesen Collection | ${twitterTitle || title || globalTwitterTitle}` },
        { name: "twitter:description", content: twitterDescription || description || globalTwitterDescription },
        { name: "twitter:image:src", content: toLocalPath(twitterImage) || toLocalPath(globalDefaultImage) },

        // { itemprop: "name", content: GoogleTitle || Title || globalGoogleTitle },
        // { itemprop: "description", content: GoogleDescription || Description || globalGoogleDescription },
        // { itemprop: "image", content: toLocalPath(GoogleImage) || toLocalPath(globalDefaultImage) }
      ]}
    >
      <link rel="canonical" href={cannonicalUrl || url}></link>
      <link rel="shortcut icon" type="image/x-icon" href={displayedFavicon} />
      { bgBanner && <link rel="preload" as="image" href={bgBanner} /> }
      {links}
    </Helmet>
  )
}

SEO.defaultProps = {
  page: {}, 
  businessSchema: ``, 
  schemas: ``, 
  globalSettings: {}, 
  headerScript: ``, 
  favicon: ``, 
  generalSeoSetting: {}
}

SEO.propTypes = {
  page: PropTypes.object, 
  businessSchema: PropTypes.string, 
  schemas: PropTypes.string, 
  globalSettings: PropTypes.object, 
  headerScript: PropTypes.string, 
  favicon: PropTypes.string, 
  generalSeoSetting: PropTypes.object
}

export default SEO
